import { createMutationKeys, mergeQueryKeys } from "@lukemorales/query-key-factory";
import { ProductionActionApiWorksWorkIdRecycleScrapPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { ProductionInstance } from "instance/axios";



export const mutateRecycle = createMutationKeys('recycle', {
    recycle: (params: ProductionActionApiWorksWorkIdRecycleScrapPutRequest) => {
        return {
            mutationKey: [params.worksWorkIdRecycleScrapPutRequest],
            mutationFn: () => ProductionInstance.worksWorkIdRecycleScrapPut(params)
        }
    },
})

export const recycleQuery = mergeQueryKeys(mutateRecycle);