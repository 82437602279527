import { Accordion, Badge, Divider, Flex } from "@mantine/core";
import { ProductionActionApiWorksWorkIdPerformancePutRequest, ProductionPlansGet200ResponseRowsInnerWorksInner, WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum, WorksWorkIdPerformancePut200Response, WorksWorkIdPerformancePutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconArticle, IconClipboardCheck, IconPlus, IconRefreshDot } from "@tabler/icons-react";
import { AxiosResponse } from "axios";
import BigNumber from "bignumber.js";
import CommonModalHandler from "common/commonModalHandler";
import { BaseText } from "components/common/Text/baseText";
import { BaseButton } from "components/common/button/baseButton";
import { WorkTable } from "components/common/table/workTable";
import { BaseTitle } from "components/common/title/BaseTitle";
import { DefectObject, DefectObjectProps } from "components/common/types";
import WorkAccordion from "components/pages/work/workAccordion/workAccordion";
import { WorkBox } from "components/pages/work/workBox/workBox";
import { WorkDefectReportBox } from "components/pages/work/workDefectReportBox/workDefectReportBox";
import { WorkEndReportBox } from "components/pages/work/workEndReportBox/workEndReportBox";
import WorkInputLogsComponent from "components/pages/work/workInputLogs/workInputLogs";
import WorkInventoriesComponent from "components/pages/work/workInventories/workInventories";
import WorkLocationsComponent from "components/pages/work/workLocation/workLocations";
import WorkProgress from "components/pages/work/workProgress/workProgress";
import { WorkStatusButton } from "components/pages/work/workStatusButton/workStatusButton";
import WorkTimeTrackComponent from "components/pages/work/workTimeTrack/workTimeTrack";
import { useLocalStorage } from "context/LocalStorageProvider";
import { useModal } from "context/ModalStackManager";
import { useGetEquipmentCounterBySetting } from "hooks/useGetEquipmentCounterBySetting";
import { useGetWorkData } from "hooks/useGetWorkData";
import useWorkActions from "hooks/useWorkActions";
import BodyLayout from "layouts/common/body";
import { LeftContent } from "layouts/pages/work/content/leftContent";
import { MainContent } from "layouts/pages/work/content/mainContent";
import { RightContent } from "layouts/pages/work/content/rightContent";
import { WorkHeader } from "layouts/pages/work/header";
import { WorkSection } from "layouts/pages/work/section";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MutateOptions } from "react-query";
import { customNotification } from "utils/notificationShow";
import { getWorkTrackingStatus } from "utils/tranferWorkStatus";
import { setToLocaleString } from "utils/unitMark";


const Work = Object.assign({}, {
    Body: BodyLayout,
    Button: BaseButton,
    StatusButton: WorkStatusButton,
    Header: WorkHeader,
    Content: MainContent,
    LeftContent: LeftContent,
    RightContent: RightContent,
    Section: WorkSection,
    Inventories: WorkInventoriesComponent,
    TimeTrack: WorkTimeTrackComponent,
    InputLogs: WorkInputLogsComponent,
    Accordion: WorkAccordion,
    WorkDefectReportBox: WorkDefectReportBox,
    WorkEndReportBox: WorkEndReportBox,
    WorkLocations: WorkLocationsComponent,
    Table: WorkTable,
    Title: BaseTitle,
    Progress: WorkProgress,
    Text: BaseText,
    Box: WorkBox
})

export const WorkByRowPage = () => {

    const { setRoutingCode } = useLocalStorage();

    const modal = useModal();
    const { closeModal } = modal;
    const { t } = useTranslation();

    const { workData, workLotsData, workInventoriesData, workInputLogsAll, workOutsourceData } = useGetWorkData();

    const { onPerformance, onDefect, onFlush, onStart, onPause,
        onEnd, onResume, onCorrect, onCancel, onSetting } = useWorkActions({ workData: workData });

    const { equipmentCounter, isLimitHit, equipmentLimitCounter } = useGetEquipmentCounterBySetting({
        workData: workData as ProductionPlansGet200ResponseRowsInnerWorksInner,
    });

    const commonModalHandler = new CommonModalHandler(modal);

    const workInputLogsData = workInputLogsAll?.filter((data) => (
        data.workLogType === WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.INPUT
    ))

    const workTrackLogsData = workInputLogsAll?.filter((data) => (
        data.workLogType === WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_END
        || data.workLogType === WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_PAUSE
        || data.workLogType === WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_RESUME
        || data.workLogType === WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_START
    )).reverse()

    const currentDefectList = Object.keys(workData?.currentLotSummary?.defect ? workData?.currentLotSummary?.defect as DefectObject : {}).map((code) => {
        return {
            defectName: (workData?.currentLotSummary?.defect as DefectObject)[code]?.defectName as string,
            defectCode: code,
            defectQuantity: (workData?.currentLotSummary?.defect as DefectObject)[code]?.defectQuantity as string,
            setUnitText: workData?.item?.unitText
        }
    })

    const activeWorkId = workData?.equipment?.activeWorkId;
    const lastAlreadyCancelWorkLog = workInputLogsAll?.[0]?.alreadyCanceledWorkLog
    const lastWorkLogType = workInputLogsAll?.[0]?.workLogType === 'INPUT';
    const isWaitingStatus = workData?.trackingStatus === "WAITING";
    const isFirstPerformance = workData?.summary?.end === '0';
    const hasInventory = workInventoriesData?.rows?.length as number > 0;
    const isInputLogExist = workInputLogsAll?.length as number > 0;
    const isNotInputPerformanceLogExist = workData?.summary?.totalQuantity === '0';

    // 첫번째 작업지시인지 판단 여부
    const isActiveWork = activeWorkId !== workData?.id && activeWorkId !== null;
    // 플러싱 여부
    const isFlush = isFirstPerformance && hasInventory && isWaitingStatus
    // 투입 이후, 실적을 입력했는가에 대한 판단 여부
    const isCheckInput = lastWorkLogType && !lastAlreadyCancelWorkLog
    // 재고보정 여부
    const isCorrect = hasInventory && (isInputLogExist && isNotInputPerformanceLogExist)

    // 작업시작
    const handleStart = async () => {

        const handleFlushOrStart = () => {
            if (isFlush) {
                return commonModalHandler.handleFlush({
                    workInventoriesData,
                    yesCallback: onFlush,
                    noCallback: () => { },
                    commonCallback: () => {
                        onStart();
                        setRoutingCode(workData?.routingCode);
                        closeModal({});
                    },
                });
            } else {
                onStart();
                setRoutingCode(workData?.routingCode);
                closeModal({});
            }
        };

        // 첫 작업일때
        if (!isActiveWork) {
            handleFlushOrStart();
        } else {
            commonModalHandler.handleActiveWork({
                yesCallback: handleFlushOrStart,
                noCallback: () => closeModal({}),
                commonCallback: () => closeModal({})
            });
        }
    };

    // 작업재개
    const handleResume = () => {

        if (!isActiveWork) {
            setRoutingCode(workData?.routingCode)
            return onResume();
        }

        commonModalHandler.handleActiveWork({
            yesCallback: onResume,
            noCallback: () => { closeModal({}) },
            commonCallback: () => { closeModal({}) }
        })
    };


    // 원부자재 투입 
    const handleInputRawMaterial = (end?: string) => {

        const handleRawMaterialModal = () => {
            commonModalHandler.handleRawMaterial({
                workData,
                yesCallback: () => {
                    if (end) {
                        handlePerformance({
                            accQuantity: end,
                        })
                    }
                },
                noCallback: () => { },
                commonCallback: () => { }
            });
        };

        const commonHandleCorrect = () => {
            commonModalHandler.handleCorrect({
                yesCallback: onCorrect,
                noCallback: () => { },
                commonCallback: () => {
                    closeModal({});
                    handleRawMaterialModal();
                },
            });
        };

        const handleInputLogModal = () => {
            commonModalHandler.handleInputLog({
                yesCallback: () => {
                    if (isCorrect) {
                        commonHandleCorrect();
                    } else {
                        handleRawMaterialModal();
                    }
                },
                noCallback: () => { },
                commonCallback: () => closeModal({})
            });
        };


        if (isCheckInput) {
            handleInputLogModal();
        } else {
            if (isCorrect) {
                commonHandleCorrect();
            } else {
                handleRawMaterialModal();
            }
        }
    };

    // 생산실적 입력 처리를 위한 함수
    const handlePerformance = (
        performanceRequest: WorksWorkIdPerformancePutRequest,
        options?: MutateOptions<AxiosResponse<WorksWorkIdPerformancePut200Response, any>, unknown, ProductionActionApiWorksWorkIdPerformancePutRequest, unknown>
    ) => {
        onPerformance(performanceRequest, options!);
    };

    // 공통 실적 입력 로직
    const performCommonPerformance = (end: string, warnInsufficiency: boolean, onSuccess: () => void) => {
        handlePerformance({
            quantity: end,
            warnInsufficiency,
        }, {
            onSuccess,
            onError: (e: any) => {
                const errorMessage = e.response?.data?.message
                // 전 생산수량보다 큰 수량을 입력해주세요
                if (errorMessage === "accQuantity is less than current accQuantity"
                    || errorMessage === "Quantity must be positive"
                    || errorMessage === "accQuantity 값이 현재 현재 accQuantity값보다 작습니다."
                ) {
                    return customNotification.error({ message: t("이전 생산수량보다 큰 수량을 입력해주세요.") })
                }
                commonModalHandler.handleOutOfStock({
                    yesCallback: () => handleInputRawMaterial(end),
                    noCallback: () => { performCommonPerformance(end, false, onSuccess) },
                    commonCallback: () => { closeModal({}) }
                });
            }
        });
    };


    // // 실적 저장 처리
    const handleSavePerformance = (end: string) => {

        const performFlushIfNeeded = async () => {
            if (isFlush) {
                commonModalHandler.handleFlush({
                    workInventoriesData,
                    yesCallback: async () => {
                        await onFlush();
                        performCommonPerformance(end, true, () => closeModal({}));
                    },
                    noCallback: () => performCommonPerformance(end, true, () => closeModal({})),
                    commonCallback: () => { }
                });
            } else {
                performCommonPerformance(end, true, () => closeModal({}));
            }
        };

        if (isActiveWork) {
            commonModalHandler.handleActiveWork({
                yesCallback: performFlushIfNeeded,
                noCallback: () => { },
                commonCallback: () => closeModal({})
            });
        } else {
            performFlushIfNeeded()
        }
    };

    // 불량 저장 
    const handleDefectSave = (defect: DefectObjectProps) => {

        //입력한 불량이 생산수량보다 많을경우
        const isDefectQuantityGreaterThanEnd = BigNumber(defect.defectQuantity)
            .isGreaterThan(workData?.summary?.end as string);
        const isDefectQuantityGreaterThanEndDetailMessage = t("불량 수량이 생산수량보다 많습니다.")

        if (isDefectQuantityGreaterThanEnd) {
            return customNotification.error({ message: isDefectQuantityGreaterThanEndDetailMessage })
        }

        onDefect(
            {
                defectCode: defect.defectCode as string,
                quantity: defect.defectQuantity
            },
        );
    }

    // 리밋 카운터 도달시  
    const handleLimitCounter = () => {

        commonModalHandler.handleLimitHit({
            workData,
            equipmentLimitCounter,
            yesCallback: () => {
                handleSavePerformance(equipmentLimitCounter)
            },
            noCallback: () => { },
            commonCallback: () => {
                closeModal({})
            }
        })
    }

    useEffect(() => {
        if (isLimitHit) {
            handleLimitCounter()
        }
    }, [isLimitHit])

    return (
        <Work.Body>
            <Work.Header>
                <Flex gap={'0.625rem'} justify={'flex-start'}>
                    <Work.Button
                        onClick={() => handleInputRawMaterial()}
                        color={'green.6'}
                        leftIcon={<IconPlus size={'2rem'} />}
                    >
                        {t('원부자재 투입')}
                    </Work.Button>
                    <Work.Button
                        onClick={() => commonModalHandler.handleInputLogs({ workData })}
                        color={'yellow.6'}
                        leftIcon={<IconClipboardCheck size={'2rem'} />}
                    >
                        {t('투입 기록')}
                    </Work.Button>
                    <Work.Button
                        onClick={() => commonModalHandler.handleInspectSpecification({ workData, lotData: workLotsData })}
                        color={'blue.6'}
                        leftIcon={<IconClipboardCheck size={'2rem'} />}
                    >
                        {t('초중종품 검사')}
                    </Work.Button>
                </Flex>
                <Flex gap={'0.625rem'} justify={'flex-end'}>
                    <Work.Button
                        color={'cyan.6'}
                        leftIcon={<IconArticle size={'2rem'} />}
                        onClick={() => commonModalHandler.handleWorkLogs({ workId: workData?.id })}
                    >
                        {t("작업로그")}
                    </Work.Button>
                    <Work.StatusButton
                        externalCounterSetting={workData?.externalCounterSetting}
                        trackingStatus={workData?.trackingStatus}
                        handleStart={handleStart}
                        handleEnd={() =>
                            commonModalHandler.handleEnd({
                                yesCallback: onEnd,
                                noCallback: () => { },
                                commonCallback: () => { closeModal({}) }
                            })}
                        handlePause={() => commonModalHandler.handlePause({ workData, onPause })}
                        handleResume={handleResume}
                    />
                </Flex>
            </Work.Header>
            <Work.Content>
                <Work.LeftContent>
                    <Work.Section>
                        <Flex justify={"space-between"} w={'100%'}>
                            <Flex direction={'column'}>
                                <Work.Title>{t("현재 작업 상태")}</Work.Title>
                                <Work.Text c="cyan">
                                    <Badge size='xl' color={getWorkTrackingStatus(workData?.trackingStatus).color}>
                                        {getWorkTrackingStatus(workData?.trackingStatus).status}
                                    </Badge>
                                </Work.Text>
                            </Flex>
                            <Flex direction={'column'}>
                                <Work.Title>{t("품목코드")}</Work.Title>
                                <Work.Text size={"2rem"} color="#E9ECEF">{workData?.item?.code ?? "-"}</Work.Text>
                            </Flex>
                            <Flex direction={'column'}>
                                <Work.Title>{t("품목명")}</Work.Title>
                                <Work.Text>{workData?.item?.name ?? "-"}</Work.Text>
                            </Flex>
                        </Flex>
                        <Flex justify={"space-between"} w={'100%'}>
                            {[
                                { title: "규격", value: workData?.item?.spec },
                                { title: "가로", value: workData?.item?.width },
                                { title: "세로", value: workData?.item?.depth },
                                { title: "높이", value: workData?.item?.height },
                                { title: "비고", value: workData?.description },
                            ].map((item, index) => {
                                return (
                                    <Flex key={index} gap={10}>
                                        <Work.Title>{t(item.title)}</Work.Title>
                                        <Work.Text>{item.value}</Work.Text>
                                    </Flex>
                                );
                            })}
                        </Flex>
                    </Work.Section>
                    <Divider size={'md'} h={0} w={'100%'} variant="solid" color="#CED4DA" />
                    <Work.Section>
                        <Flex w={"100%"} direction={'column'}>
                            <Flex>
                                <Work.Title>
                                    {t("진행률")}
                                </Work.Title>
                                <Work.Title>({t("목표 생산량")} : {workData?.targetQuantity} {workData?.item?.unitText})</Work.Title>
                            </Flex>
                            <Flex w={"100%"} gap={10} align={'center'} justify={'space-between'}>
                                <Work.Progress
                                    w={"90%"}
                                    value={Number(Number(workData?.summary?.percent).toFixed(2))}
                                />
                                <Work.Text >
                                    {Number(Number(workData?.summary?.percent).toFixed(2))}%
                                </Work.Text>
                            </Flex>
                            <Flex justify={"space-between"} mb={8} w={"100%"}>
                                {[
                                    {
                                        title: "생산 수량",
                                        color: 'violet.4',
                                        children: setToLocaleString(workData?.summary?.end),
                                    },
                                    {
                                        title: "양품 수량",
                                        color: 'teal.4',
                                        children: setToLocaleString(workData?.summary?.totalQuantity),
                                    },
                                    {
                                        title: "불량 수량",
                                        color: 'red.4',
                                        children: setToLocaleString(workData?.summary?.defectTotal),
                                    },
                                    {
                                        title: "생산 잔량",
                                        color: 'orange.4',
                                        children: setToLocaleString(workData?.summary?.todoQuantity),
                                    },
                                ].map((data, index) => (
                                    <Flex key={index} p={10} gap={10} direction={'column'} align={'flex-end'}>
                                        <Work.Text
                                            fz={"2.125rem"}
                                            lh={1.3}
                                            color={data.color}
                                        >
                                            {data.children}
                                        </Work.Text>
                                        <Work.Title
                                            fz={"2.125rem"}
                                            lh={1.3}
                                        >
                                            {t(data.title)}
                                        </Work.Title>
                                    </Flex>
                                ))}
                            </Flex>
                        </Flex>
                    </Work.Section>
                    <Divider size={'md'} h={0} w={'100%'} variant="solid" color="#CED4DA" />
                    <Work.Section>
                        <Work.Accordion>
                            <Accordion.Item value="ledgerTable">
                                <Accordion.Control>
                                    <Flex w={"100%"} justify={"space-between"}>
                                        <Work.Title
                                            fz={"1.625rem"}
                                            c={"#FFFFFF"}
                                        >
                                            {t("생산가능재고")}
                                        </Work.Title>
                                        {workData?.routingOutsourceId && (
                                            <Work.Box>{`${t("외주")} : ${workOutsourceData?.name}`}</Work.Box>
                                        )}
                                        <Work.Button
                                            color="gray.5"
                                            size="md"
                                            onClick={() => {
                                                if (hasInventory) {
                                                    commonModalHandler.handleFlush({
                                                        workInventoriesData,
                                                        yesCallback: onFlush,
                                                        noCallback: () => { },
                                                        commonCallback: () => { closeModal({}) }
                                                    })
                                                }
                                            }}
                                            rightIcon={<IconRefreshDot size={'2rem'} />}
                                        >
                                            {t("재고 초기화")}
                                        </Work.Button>
                                    </Flex>
                                </Accordion.Control>
                                <Accordion.Panel>
                                    <Work.Inventories workData={workData} workInventoriesData={workInventoriesData} />
                                </Accordion.Panel>
                            </Accordion.Item>
                        </Work.Accordion>
                    </Work.Section>
                    <Work.Section>
                        <Work.Accordion>
                            <Accordion.Item value="ledgerTable">
                                <Accordion.Control>
                                    <Flex w={"100%"} justify={"space-between"}>
                                        <Work.Title
                                            fz={"1.625rem"}
                                            c={"#FFFFFF"}
                                        >
                                            {t("로케이션")}
                                        </Work.Title>
                                    </Flex>
                                </Accordion.Control>
                                <Accordion.Panel>
                                    <Work.WorkLocations workData={workData} />
                                </Accordion.Panel>
                            </Accordion.Item>
                        </Work.Accordion>
                    </Work.Section>
                </Work.LeftContent>
                <Divider orientation="vertical" size={'md'} />
                <Work.RightContent>
                    <Work.Section>
                        <Work.Accordion>
                            <Accordion.Item value="workTable">
                                <Accordion.Control>
                                    <Work.Title
                                        fz={"1.625rem"}
                                        c={"#FFFFFF"}
                                    >
                                        {t("작업시간 추적")}
                                    </Work.Title>
                                </Accordion.Control>
                                <Accordion.Panel>
                                    <Work.TimeTrack
                                        workTrackLogsData={workTrackLogsData}
                                    />
                                </Accordion.Panel>
                            </Accordion.Item>
                        </Work.Accordion>
                    </Work.Section>
                    <Work.Section>
                        <Work.Accordion>
                            <Accordion.Item value="currentDefectTable">
                                <Accordion.Control>
                                    <Work.Title
                                        fz={"1.625rem"}
                                        c={"#FFFFFF"}
                                    >
                                        {t("불량기록")}
                                    </Work.Title>
                                </Accordion.Control>
                                <Accordion.Panel>
                                    <Work.Table
                                        columns={[t("불량명"), t("보고수량")]}
                                        flex={[2, 1]}
                                        data={currentDefectList?.map((defect) => {
                                            return defect?.defectQuantity !== '0' ? [
                                                defect?.defectName ?? "-",
                                                <div style={{ textAlign: 'right' }}>{(defect?.defectQuantity ?? "-") + " " + defect?.setUnitText}</div>,
                                            ] : [];
                                        }) ?? [[]]}
                                    />
                                </Accordion.Panel>
                            </Accordion.Item>
                        </Work.Accordion>
                    </Work.Section>
                    <Work.Section>
                        <Work.WorkDefectReportBox
                            value={workData?.summary?.defect as DefectObject}
                            onSubmit={handleDefectSave}
                        />
                        <Work.WorkEndReportBox
                            activeType={workData?.trackingStatus}
                            onChangeSetting={onSetting}
                            externalCounterSetting={workData?.externalCounterSetting ?? "COUNTER_AND_HANDWRITING"}
                            disabled={workData?.externalCounterSetting === "COUNTER_AND_AUTO_REPORT"}
                            value={equipmentCounter || "0"}
                            onSubmit={handleSavePerformance}
                        />
                    </Work.Section>
                </Work.RightContent>
            </Work.Content>
        </Work.Body >
    );
}