import { Button, Flex, Group, Loader, Radio, Select, Table, TextInput } from "@mantine/core";

import { useForm } from "@mantine/form";
import { ProductionActionApiWorksWorkIdRecycleScrapPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { INVENTORIES_KEY } from "api/inventories/useInventoriesQuery";
import { WORK_LOGS_KEY } from "api/logs/useWorksLogsQuery";
import { mutateRecycle } from "api/recycle/useRecycle";
import { InventoriesAutoComplete } from "components/common/autoComplete/inventory/inventories-autoComplete";
import { ItemAutoComplete } from "components/common/autoComplete/item/item-autoComplete";
import { LotAutoComplete } from "components/common/autoComplete/lots/lots-autoComplete";
import { FormButtonBox } from "components/common/form/FormButtonBox";
import { FormMain } from "components/common/form/FormMain";
import { FormWrapper } from "components/common/form/FormWrapper";
import { useModal } from "context/ModalStackManager";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { customNotification } from "utils/notificationShow";

const Create = Object.assign({}, FormMain, {
    Button: Button,
    Input: TextInput,
    Select: Select,
    Wrapper: FormWrapper,
    ButtonBox: FormButtonBox,
    LotAutoComplete: LotAutoComplete,
    InventoriesAutoComplete: InventoriesAutoComplete,
    ItemAutoComplete: ItemAutoComplete,
});

export interface WorkInputFormProps {
    workData: any;
    lotId?: number;
    onInputSuccess?: () => void;
}

export interface RoutingBomProps {
    itemCode: string;
    routingCode: string;
    item: {
        name: string;
    };
}

export const RecycleForm = ({
    workData,
    onInputSuccess
}: WorkInputFormProps) => {

    const [itemCodes, setItemCodes] = useState<string | undefined>(workData?.routingData?.routingBoms?.[0].itemCode);
    const { closeModal } = useModal();
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    const { mutate: RecycleMutate, isLoading } = useMutation(
        (params: ProductionActionApiWorksWorkIdRecycleScrapPutRequest) =>
            mutateRecycle.recycle(params).mutationFn(undefined),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(INVENTORIES_KEY);
                queryClient.invalidateQueries(WORK_LOGS_KEY);
            }
        }
    )

    const form = useForm({
        initialValues: {
            barcodeInput: '',
            workId: workData?.id,
            worksWorkIdInputPutRequest: {
                lotId: '',
                sourceLocationCode: undefined,
                // routingsData는 배열이므로, find로 해당 routingCode에 맞는 데이터를 찾아야 함
                targetLocationCode:
                    workData?.equipment?.toLocationCode ??
                    workData?.routingData?.operation?.toLocationCode ??
                    workData?.productionPlan?.routingsData.find(
                        (data: any) => data.code === workData.routingCode
                    ).operation.toLocationCode,
                quantity: workData.summary?.defectTotal,
            },
        },
    });

    useEffect(() => {
        if (workData) {
            form.setValues({
                barcodeInput: '',
                workId: workData.id,
                worksWorkIdInputPutRequest: {
                    lotId: '',
                    sourceLocationCode: undefined,
                    targetLocationCode:
                        workData?.currentRoutingOutsourceData?.toLocationCode ??
                        workData?.equipment?.toLocationCode ??
                        workData?.routingData?.operation?.toLocationCode ??
                        workData?.productionPlan?.routingsData.find(
                            (data: any) => data.code === workData.routingCode
                        ).operation.toLocationCode,
                    quantity: workData.summary?.defectTotal,
                },
            });
        }
    }, [workData]);

    const onSubmit = () => {
        try {
            RecycleMutate(
                {
                    workId: form.values.workId && (form.values.workId as number),
                    worksWorkIdRecycleScrapPutRequest: {
                        itemCode: itemCodes as string,
                        lotId: parseInt(form?.values?.worksWorkIdInputPutRequest?.lotId),
                        locationCode: workData?.routingData?.operation?.fromLocationCode,
                        quantity: form?.values?.worksWorkIdInputPutRequest?.quantity,
                    }
                },
                {
                    onSuccess: () => {
                        customNotification.success({
                            message: t("스크랩 재활용에 성공적으로 투입되었습니다."),
                        });
                        onInputSuccess && onInputSuccess(); // 실적 저장시 원부자재 투입이 된다면 실적저장 함수를 실행
                        closeModal(form.values);
                    },
                }
            );
        } catch (e) {
            customNotification.error({
                message: t("스크랩 재활용에 실패하였습니다."),
            });
        }
    };

    const invalidConfirm = form.values.worksWorkIdInputPutRequest.lotId === undefined
        || form.values.worksWorkIdInputPutRequest.quantity === '0'
        || form.values.worksWorkIdInputPutRequest.quantity === '';


    if (isLoading) return <Flex w="100%" h="100%" justify="center" align="center"><Loader size="30rem" /></Flex>

    return (
        <>
            <Create.Wrapper>
                <Table>
                    <thead>
                        <tr>
                            <th>
                                <span
                                    style={{ marginInline: "3rem 12rem", fontSize: "1.4rem" }}
                                >
                                    {t("품목코드")}
                                </span>
                                <span
                                    style={{ fontSize: "1.4rem", marginInline: "2rem 16rem" }}
                                >
                                    {t("품목명")}
                                </span>
                                <span style={{ fontSize: "1.4rem" }}>{t("라우팅코드")}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <Radio.Group
                            value={itemCodes}
                            onChange={(e) => {
                                setItemCodes(e);
                                form.setFieldValue(
                                    "worksWorkIdInputPutRequest.lotId",
                                    undefined
                                );
                            }}
                        >
                            {workData?.routingData?.routingBoms?.map(
                                (value: RoutingBomProps, index: number) => {
                                    return (
                                        <tr key={index}>
                                            <Group style={{ gap: "2rem" }}>
                                                <Radio
                                                    value={value.itemCode} />
                                                <td width="180rem">{value.itemCode}</td>
                                                <td width="180rem">{value.item.name}</td>
                                                <td width="180rem">{value.routingCode}</td>
                                            </Group>
                                        </tr>
                                    );
                                }
                            )}
                        </Radio.Group>
                    </tbody>
                </Table>
                <Create.InventoriesAutoComplete
                    hasZeroQuantity
                    key={form.values.worksWorkIdInputPutRequest.lotId}
                    label={t("로트 정보")}
                    withAsterisk
                    workData={workData}
                    itemCodes={itemCodes ?? workData?.routingData?.routingBoms?.map((value: RoutingBomProps) => value.itemCode)}
                    {...form.getInputProps("worksWorkIdInputPutRequest.lotId")}
                    size="lg"
                    styles={{ label: { fontSize: "0.9em" } }}
                />
                <Create.Input
                    label={t("로케이션 코드")}
                    withAsterisk
                    disabled
                    size="lg"
                    value={workData?.currentRoutingOutsourceData?.fromLocationCode ?? workData?.routingData?.operation?.fromLocationCode}
                    styles={{ label: { fontSize: "0.9em" } }}
                />
                <Create.Input
                    withAsterisk
                    type="number"
                    label={t("수량")}
                    size="lg"
                    styles={{ label: { fontSize: "0.9em" } }}
                    {...form.getInputProps("worksWorkIdInputPutRequest.quantity")}
                />
                <Create.ButtonBox>
                    <Create.Button color="gray" onClick={closeModal} size="lg" fz="xl">
                        {t("취소")}
                    </Create.Button>
                    <Create.Button
                        disabled={invalidConfirm}
                        onClick={onSubmit} size="lg" fz="xl">
                        {t("저장")}
                    </Create.Button>
                </Create.ButtonBox>
            </Create.Wrapper>
        </>
    );
};
